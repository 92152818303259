<template>
  <div class="input-fields">
    <AppHeader
      :title="$translate('Details')"
      display-back-button
      :has-error="hasError"
      @next="nextStep"
      @back="back"
    />
    <v-form ref="form">
      <v-container>
        <component
          :is="getRenderer(field)"
          v-for="field in fields"
          :key="field.id"
          :field="field"
          :data-field-id="field.id"
        />
      </v-container>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import { dynamicFormService } from '@/dynamic-form/dynamic-form-service';
import type { ITextInputData, IVisitFormInputField, IVisitFormInputFieldsSection, VisitFormSectionType } from '@einfachgast/shared';
import { useRoute } from 'vue-router';
import { computed, ref, defineAsyncComponent } from 'vue';
import { useGroupsStore } from '@/store/groups';
import AppHeader from '@/components/AppHeader.vue';
import { useSectionNavigation } from '@/dynamic-form/composables/use-section-navigation';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { denyCheckin } from '@/dynamic-form/composables/use-deny-checkin';

const form = ref<{ validate: () => { valid: boolean } }>();
const hasError = ref(false);
const { next } = useSectionNavigation();
const { executeDenyOnValidators } = useVisitConditionsStore();
const { back } = useSectionNavigation();

const route = useRoute();
const groupsStore = useGroupsStore();
const section: IVisitFormInputFieldsSection = dynamicFormService.getCurrentStep(route.params.step as VisitFormSectionType) as IVisitFormInputFieldsSection;

const fields = computed(() => {
  let fields = section?.inputFields || [];
  if (groupsStore.selectedGroup) {
    fields = fields.filter(field => {
      return field.groups.length === 0 || field.groups?.includes(groupsStore.selectedGroup?.id as string);
    });
  }
  return fields;
});

const nextStep = async () => {
  // Field Validation
  if (!await validate()) {
    hasError.value = true;
    return;
  }
  // Special Checks for deny Reasons
  const denyOnResult = executeDenyOnValidators();
  if (!denyOnResult.isValid){
    denyCheckin(denyOnResult.error, true);
    return;
  };
  next();
};

const validate = async () => {
  const validationResult = await form.value?.validate();
  if(!validationResult?.valid) {
    return false;
  }
  return true;
};

const rendererConfig = {
  boolean: defineAsyncComponent(() => import('../../renderers/fields/BooleanFieldRenderer.vue')),
  signature: defineAsyncComponent(() => import('../../renderers/fields/SignatureFieldRenderer.vue')),
  select: defineAsyncComponent(() => import('../../renderers/fields/SelectFieldRenderer.vue')),
  text: defineAsyncComponent(() => import('../../renderers/fields/TextInputRenderer.vue')),
  number: defineAsyncComponent(() => import('../../renderers/fields/NumberInputRenderer.vue')),
  multiselect: defineAsyncComponent(() => import('../../renderers/fields/MultiselectFieldRenderer.vue')),
  groupselect: defineAsyncComponent(() => import('../../renderers/fields/TextInputRenderer.vue')),
};

const getFieldRenderer = (field: IVisitFormInputField<ITextInputData>) => {
  if (field.data.format === 'signature') {
    return rendererConfig['signature'];
  }
  if (field.data.format === 'number') {
    return rendererConfig['number'];
  }

  return rendererConfig[field.type];
};

const getRenderer = (field: IVisitFormInputField) => {
  return getFieldRenderer(field);
};
</script>

